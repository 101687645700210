import React, {FC} from 'react';
import '../../../styles/typography.scss';
import './styles.scss';
import linkedin from '../../../assets/socials/linkedin.svg';
interface LeaderIProps {
  img: string;
  fullName: string;
  position: string;
  hrefLinkedin: string;
}

const Leader: FC<LeaderIProps> = ({img, fullName, position, hrefLinkedin}) => {
  return (
    <div className="leader-container">
      <img src={img}></img>
      <div className="secondary-text fullName">{fullName}</div>
      <div>
        <a href={hrefLinkedin} target="_blank" rel='noopener'>
          <img src={linkedin}></img>
        </a>
        <div className="position">{position}</div>
      </div>
    </div>
  );
};

export default Leader;
